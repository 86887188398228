import { Route } from 'vue-router/types/router';

import {
  EAlgoliaCoreSearchCriteria,
  EFacetNameNumericMenu,
  EFacetNameRange,
  EFacetNameRefinementList,
  EFacetNameToggleRefinement,
  FacetNameNumericMenu,
  FacetNameRange,
  FacetNameToggleRefinement,
  HierarchicalMenuCategoriesSearchCriteria,
} from '@/domain/core/algolia/types';
import { ERouteName } from '@/domain/core/Routes.enum';
import { FilterValues, RouteState, StateMapping, UiState } from '@/infrastructure/externalServices/algolia/types';

// NOTE: Either type this file properly or don't type it at all.
export const _createStateMapping = (indexName: string, route: Route): StateMapping => {
  const isSearchSeoRoute = route?.name === ERouteName.SearchSeo;

  return {
    stateToRoute(uiState: UiState): RouteState {
      const { HierarchicalCategoriesLevel1 } = EFacetNameRefinementList;
      const { hierarchicalMenu, numericMenu, page, query, range, refinementList, sortBy, toggle } = uiState?.[indexName] || {};
      let localRouteState: RouteState = {};

      if (refinementList?.country === undefined && refinementList?.departmentFacet) {
        delete refinementList.departmentFacet;
      }

      if (refinementList !== undefined) {
        for (const key of Object.keys(refinementList)) {
          const refinementValue = refinementList[key];

          localRouteState[key] = refinementValue?.join('~');
        }
      }

      if (toggle !== undefined) {
        localRouteState = {
          ...localRouteState,
          ...toggle,
        };
      }

      if (range !== undefined) {
        localRouteState = {
          ...localRouteState,
          ...range,
        };
      }

      if (numericMenu !== undefined) {
        localRouteState = {
          ...localRouteState,
          ...numericMenu,
        };
      }

      if (!isSearchSeoRoute) {
        localRouteState = {
          ...localRouteState,
          query,
        };
      }

      if (hierarchicalMenu?.[HierarchicalCategoriesLevel1]) {
        localRouteState[HierarchicalMenuCategoriesSearchCriteria] = hierarchicalMenu[HierarchicalCategoriesLevel1]?.join('~');
      }

      return {
        ...localRouteState,
        sortBy,
        page,
      };
    },
    routeToState(routeState: RouteState): UiState {
      const localUiState: FilterValues = {};

      for (const key of Object.keys(routeState)) {
        const routeStateValue = routeState[key];
        const isSortByWidget = key === EAlgoliaCoreSearchCriteria.SortBy;
        const isPaginationWidget = key === EAlgoliaCoreSearchCriteria.Page;
        const isQueryWidget = key === EAlgoliaCoreSearchCriteria.Query;
        const isHierarchicalMenuCategoriesWidget = key === HierarchicalMenuCategoriesSearchCriteria;
        const isToggleWidget = Object.values(EFacetNameToggleRefinement).includes(key as FacetNameToggleRefinement);
        const isRangeWidget = Object.values(EFacetNameRange).includes(key as FacetNameRange);
        const isNumericMenuWidget = Object.values(EFacetNameNumericMenu).includes(key as FacetNameNumericMenu);

        if (isSortByWidget) {
          localUiState.sortBy = routeStateValue as string;
        } else if (isNumericMenuWidget) {
          if (!localUiState.numericMenu) {
            localUiState.numericMenu = {};
          }

          localUiState.numericMenu[key] = routeStateValue as string;
        } else if (isQueryWidget) {
          localUiState.query = routeStateValue as string;
        } else if (isPaginationWidget) {
          localUiState.page = routeStateValue as string;
        } else if (isToggleWidget) {
          if (!localUiState.toggle) {
            localUiState.toggle = {};
          }

          localUiState.toggle[key] = routeStateValue as boolean;
        } else if (isRangeWidget) {
          if (!localUiState.range) {
            localUiState.range = {};
          }

          localUiState.range[key] = routeStateValue as string;
        } else if (isHierarchicalMenuCategoriesWidget) {
          if (!localUiState.hierarchicalMenu) {
            localUiState.hierarchicalMenu = {};
          }

          localUiState.hierarchicalMenu[EFacetNameRefinementList.HierarchicalCategoriesLevel1] = `${routeStateValue}`?.split('~');
        } else {
          if (!localUiState.refinementList) {
            localUiState.refinementList = {};
          }

          localUiState.refinementList[key] = `${routeStateValue}`?.split('~');
        }
      }

      const slugParam = route?.params?.slug;

      if (isSearchSeoRoute && slugParam) {
        localUiState.query = slugParam.replaceAll('-', ' ');
      }

      return {
        [indexName]: localUiState,
      };
    },
  };
};
